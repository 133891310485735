import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Menu from "./Menu";

const coord = elem => {
  let rect = elem.getBoundingClientRect();
  let left =
          rect.left + (window.pageXOffset || document.documentElement.scrollLeft),
      top = rect.top + (window.pageYOffset || document.documentElement.scrollTop),
      right = left + elem.clientWidth,
      bottom = top + elem.clientHeight;
  return { left, right, top, bottom };
};

const useStyles = createUseStyles({
  menu: {
    position: "absolute",
  }
});

const AnchorMenu = forwardRef(
    ({ anchorEl, children, className, ...props }, ref) => {
      const defaultCoords = { bottom: 0, right: 0 }; // Default values, adjust as needed

      const { right, bottom } = anchorEl ? coord(anchorEl) : defaultCoords;

      const classes = useStyles();

      if (anchorEl) {
        return (
            <Menu
                {...props}
                ref={ref}
                className={classes.menu + (className ? " " + className : "")}
                style={{ top: bottom, right: window.innerWidth - right }}
            >
              {children}
            </Menu>
        );
      } else return null;
    }
);

AnchorMenu.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default AnchorMenu;
