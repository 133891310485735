const rooms = [
    {
        id: 0,
        name: "Moderno",
        alt: "Sala de Estar Moderna",
        description: "Modernidade em forma de lar",
        src:
            "/casa1/Fachada_Jennifer_03_P.png",
        itemIds: [1, 2, 3, 4, 5, 6, 7, 8]
    }
];

const items = [
    {
        id: 1,
        preview:
            "/casa1/Fachada_Jennifer_01_P.png",
        images: [
            "/casa1/Fachada_Jennifer_01_P.png",
            "/casa1/Fachada_Jennifer_02_P.png",
            "/casa1/Fachada_Jennifer_03_P.png",
        ],
        brand: "Fachada",
        name: "Fachada",
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
        price: 25.0
    },
    {
        id: 2,
        preview:
            "/casa1/Sala_Integrada_Jennifer_08_P.png",
        images: [
            "/casa1/Sala_Integrada_Jennifer_08_P.png",
            "/casa1/Sala_Integrada_Jennifer_01_P.png",
            "/casa1/Sala_Integrada_Jennifer_04_P.png",
            "/casa1/Sala_Integrada_Jennifer_03_P.png",
            "/casa1/Sala_Integrada_Jennifer_07_P.png",
        ],
        brand: "Ambientes Integrados",
        name: "Ambientes Integrados",
        description: 'dark gray, 27 1/2x13 3/4x31 1/2 " (70x35x80 cm)',
        price: 135.0
    },
    {
        id: 3,
        preview:
            "/casa1/Sala_Integrada_Jennifer_05_P.png",
        images: [
            "/casa1/Sala_Integrada_Jennifer_05_P.png",
            "/casa1/Sala_Integrada_Jennifer_06_P.png",

        ],
        brand: "Area Gourmet",
        name: "Area Gourmet",
        description: 'dark gray, 27 1/2x13 3/4x31 1/2 " (70x35x80 cm)',
        price: 135.0
    },
    {
        id: 4,
        preview:
            "/casa1/Escritório_jennifer_01_P.png",
        images: [
            "/casa1/Escritório_jennifer_01_P.png",
            "/casa1/Escritório_jennifer_02_P.png",
        ],
        brand: "Escritório",
        name: "Escritório",
        description: "rattan, anthracite",
        price: 79.99
    },
    {
        id: 5,
        preview:
            "/casa1/Lavabo_Jennifer_01_P.png",
        images: [
            "/casa1/Lavabo_Jennifer_01_P.png",
            "/casa1/Lavabo_Jennifer_02_P.png",
        ],
        brand: "Lavabo",
        name: "Lavabo",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.\n" +
            "\n",
        price: 199.0
    },
    {
        id: 6,
        preview:
            "/casa1/Lavanderia_Jennifer_01-2_P.png",
        images: [
            "/casa1/Lavanderia_Jennifer_01-2_P.png",
            "/casa1/Lavanderia_Jennifer_02_P.png",
        ],
        brand: "Lavanderia",
        name: "Lavanderia",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        price: 199.0
    },
    {
        id: 7,
        preview:
            "/casa1/Suite_master_Jennifer_01_P.png",
        images: [
            "/casa1/Suite_master_Jennifer_01_P.png",
            "/casa1/Suite_master_Jennifer_02_P.png",
            "/casa1/Suite_master_Jennifer_03_P.png",
            "/casa1/Suite_master_Jennifer_04_P.png",
            "/casa1/Closet_Jennifer_01_P.png",
            "/casa1/Banheiro_Master_Jennifer_01_P.png",
            "/casa1/Banheiro_Master_Jennifer_01-1_P.png",
            "/casa1/Banheiro_Master_Jennifer_02_P.png",
        ],
        brand: "Suite Master",
        name: "Suite Master",
        description: "birch veneer, Robust Glose off-white",
        price: 199.0
    },
    {
        id: 8,
        preview: "/casa1/Banheiro_fundo_Jennifer_01_P.png",
        images: [
			"/casa1/Banheiro_fundo_Jennifer_01_P.png",
            "/casa1/Banheiro_fundo_Jennifer_02_P.png",
        ],
        brand: "Suite 1 e 2",
        name: "Suite 1 e 2",
        description: 'white, 27x64 " (68.6x163 cm)',
        price: 29.99
    },


    {
        id: 99,
        preview:
            "/casa1/Sala_Integrada_Jennifer_08_P.png",
        images: [
            "/casa1/Sala_Integrada_Jennifer_03_P.png",
            "/casa1/Sala_Integrada_Jennifer_04_P.png",
            "/casa1/Sala_Integrada_Jennifer_05_P.png",
            "/casa1/Sala_Integrada_Jennifer_06_P.png",
            "/casa1/Sala_Integrada_Jennifer_07_P.png",
            "/casa1/Sala_Integrada_Jennifer_08_P.png",
        ],
        brand: "POÄNG",
        name: "Armchair",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.\n" +
            "\n",
        price: 199.0
    },


    {
        id: 21,
        preview:
            "https://www.ikea.com/images/dark-brown-leather-lidhult-sofa-decorated-with-sanela-cushio-afdfc438e21a2ba000b4bcba39240eb9.jpg?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/sanela-cushion-cover-dark-red__0888909_PE682834_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/sanela-cushion-cover-dark-red__0888897_PE682832_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/sanela-cushion-cover-dark-red__0888897_PE682832_S5.JPG?f=xl",

            "https://www.ikea.com/ca/en/images/products/sanela-cushion-cover-dark-red__0888912_PE682835_S5.JPG?f=xl"
        ],
        brand: "SANELA",
        name: "Cushion cover",
        description: 'dark red, 16x26 " (40x65 cm)',
        price: 9.99
    },
    {
        id: 22,
        preview:
            "https://www.ikea.com/images/listerby-round-brown-wood-coffee-table-with-shelf-underneath-a51aeeb8ff4be5a82b900cf372a9039c.jpg?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/listerby-coffee-table-brown__0835984_PE693238_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/listerby-coffee-table-brown__0705840_PH155559_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/listerby-coffee-table-brown__0676412_PH155555_S5.JPG?f=xl"
        ],
        brand: "LISTERBY",
        name: "Coffee table",
        description: 'brown, 35 3/8 " (90 cm)',
        price: 179.0
    },
    {
        id: 23,
        preview:
            "https://www.ikea.com/images/two-dark-grey-strandmon-armchairs-with-sanela-pastel-coloure-9203893cab2773177bc72bdffa25ed7a.jpg?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/listerby-coffee-table-brown__0835984_PE693238_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/listerby-coffee-table-brown__0705840_PH155559_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/listerby-coffee-table-brown__0676412_PH155555_S5.JPG?f=xl"
        ],
        brand: "STRANDMON",
        name: "Armchair",
        description: "Nordvalla dark gray",
        price: 299.0
    },
    {
        id: 24,
        preview:
            "https://www.ikea.com/images/evedal-hanging-pendant-lamps-arranged-at-different-heights-a-6ab0d2486a8c530195ef8a806b66d1fb.jpg?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/evedal-pendant-lamp__0880982_PE680880_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/evedal-pendant-lamp__0702740_PH155545_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/evedal-pendant-lamp__0795471_PH163226_S5.JPG?f=xl"
        ],
        brand: "EVEDAL",
        name: "Pendant lamp",
        description: "gray",
        price: 149.0
    },
    {
        id: 25,
        preview:
            "https://www.ikea.com/images/an-evedal-dome-shaped-table-lamp-next-to-three-glittrig-whit-bd6a1f7a02c03660ac4bc54be9fe251a.jpg?f=xxxl",
        images: [
            "https://www.ikea.com/ca/en/images/products/evedal-table-lamp__0879408_PE712302_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/evedal-table-lamp__0686393_PH155558_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/evedal-table-lamp__0879422_PE712304_S5.JPG?f=xl"
        ],
        brand: "EVEDAL",
        name: "Table lamp",
        description: "gray",
        price: 199.0
    },
    {
        id: 31,
        preview:
            "https://www.ikea.com/ca/en/images/products/lampan-table-lamp__0879365_PE674068_S5.JPG?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/lampan-table-lamp__0879372_PE674069_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/lampan-table-lamp__0743229_PH158299_S5.JPG?f=xl"
        ],
        brand: "MORABO",
        name: "Table lamp",
        description: 'brown, 11 " (29 cm)',
        price: 8.99
    },
    {
        id: 32,
        preview:
            "https://www.ikea.com/ca/en/images/products/glittrig-candlestick-set-of-3-ivory-gold-colour__0902886_PE685232_S5.JPG?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/glittrig-candlestick-set-of-3-ivory-gold-colour__0663508_PH153605_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/glittrig-candlestick-set-of-3-ivory-gold-colour__0686393_PH155558_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/glittrig-candlestick-set-of-3-ivory-gold-colour__0902883_PE685231_S5.JPG?f=xl"
        ],
        brand: "GLITTRIG",
        name: "Candlestick",
        description: "set of 3, ivory, gold-colour",
        price: 19.99
    },
    {
        id: 33,
        preview:
            "https://www.ikea.com/ca/en/images/products/radviken-armchair-dark-brown-black__0837327_PE601023_S5.JPG?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/radviken-armchair-dark-brown-black__0325828_PE517686_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/radviken-armchair-dark-brown-black__0325825_PE517684_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/radviken-armchair-dark-brown-black__0325827_PE517685_S5.JPG?f=xl"
        ],
        brand: "RÅDVIKEN",
        name: "Armchair",
        description: "dark brown, black",
        price: 199.0
    },
    {
        id: 34,
        preview:
            "https://www.ikea.com/ca/en/images/products/stockholm-nest-of-tables-set-of-2-walnut-veneer__0837170_PE601372_S5.JPG?f=xl",
        images: [
            "https://www.ikea.com/ca/en/images/products/stockholm-nest-of-tables-set-of-2-walnut-veneer__0258118_PE402030_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/stockholm-nest-of-tables-set-of-2-walnut-veneer__0258118_PE402030_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/stockholm-nest-of-tables-set-of-2-walnut-veneer__0837158_PE365379_S5.JPG?f=xl"
        ],
        brand: "STOCKHOLM",
        name: "Nesting tables",
        description: "set of 2, walnut veneer",
        price: 299.0
    },

    {
        id: 35,
        preview:
            "https://www.ikea.com/images/closeup-of-stockholm-mirrors-deep-ledge-holding-small-perfum-a473667de2f3799fa726e81cd939274f.jpg?f=m",
        images: [
            "https://www.ikea.com/ca/en/images/products/stockholm-mirror-walnut-veneer__0906302_PE555467_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/stockholm-mirror-walnut-veneer__0906306_PE658294_S5.JPG?f=xl",
            "https://www.ikea.com/ca/en/images/products/stockholm-mirror-walnut-veneer__0212204_PE362775_S5.JPG?f=xl"
        ],
        brand: "STOCKHOLM",
        name: "Mirror",
        description: 'walnut veneer, 31 1/2 " (80 cm)',
        price: 99.0
    }
];

const getRooms = () => rooms;

const getItems = roomId => {
    const {itemIds} = rooms[roomId];
    return items
        .filter(item => itemIds.includes(item.id))
        .map(item => ({id: item.id, name: item.name, preview: item.preview}));
};

const getItem = itemId => {
    return items.filter(item => item.id === Number(itemId))[0];
};

const getNextItemId = (roomId, itemId) => {
    const {itemIds} = rooms[roomId];
    for (let i = 0; i < itemIds.length; i++) {
        if (itemIds[i] === Number(itemId) && i < itemIds.length - 1)
            return itemIds[i + 1];
    }
    return 0;
};

const getPrevItemId = (roomId, itemId) => {
    const {itemIds} = rooms[roomId];
    for (let i = 0; i < itemIds.length; i++) {
        if (itemIds[i] === Number(itemId) && i > 0) return itemIds[i - 1];
    }
    return 0;
};

export {getRooms, getItems, getItem, getNextItemId, getPrevItemId};
