import React, {forwardRef} from "react";

const LeftArrowIcon = forwardRef((props, ref) => (
    <svg
        t="1584613183058"
        className="icon"
        viewBox="0 0 1433 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2805"
        width="32"
        height="32"
        ref={ref}
    >
        <path
            d="M1433.6 614.4H409.6l256 256-153.6 153.6L0 512 512 0l153.6 153.6-256 256h1024z"
            fill="currentColor"
            p-id="2806"
        ></path>
        <path
            d="M1024 409.6v204.8h409.6V409.6z"
            fill="currentColor"
            p-id="2807"
        ></path>
    </svg>
));

const RightArrowIcon = forwardRef((props, ref) => (
    <svg
        t="1584613130103"
        className="icon"
        viewBox="0 0 1433 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2474"
        width="32"
        height="32"
        ref={ref}
    >
        <path
            d="M0 614.4h1024l-256 256 153.6 153.6 512-512L921.6 0l-153.6 153.6 256 256H0z"
            fill="currentColor"
            p-id="2475"
        ></path>
        <path
            d="M409.6 409.6v204.8H0V409.6z"
            fill="currentColor"
            p-id="2476"
        ></path>
    </svg>
));

const CartIcon = forwardRef((props, ref) => (
    <svg
        t="1585123812662"
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2138"
        width="32"
        height="32"
        ref={ref}
    >
        <path
            d="M912.64 200A21.312 21.312 0 0 0 896 192H170.666667v426.666667h640c10.005333 0 18.645333-6.954667 20.842666-16.704l85.333334-384a21.333333 21.333333 0 0 0-4.202667-17.962667z"
            fill="currentColor"
            p-id="2139"
        ></path>
        <path
            d="M981.333333 746.666667H170.666667a21.333333 21.333333 0 0 1-21.333334-21.333334V106.666667H42.666667a21.333333 21.333333 0 1 1 0-42.666667h128a21.333333 21.333333 0 0 1 21.333333 21.333333v618.666667h789.333333a21.333333 21.333333 0 1 1 0 42.666667z"
            fill="currentColor"
            p-id="2140"
        ></path>
        <path
            d="M277.333333 789.333333a85.333333 85.333333 0 1 0 0 170.666667 85.333333 85.333333 0 0 0 0-170.666667zM853.333333 789.333333a85.333333 85.333333 0 1 0 0 170.666667 85.333333 85.333333 0 0 0 0-170.666667z"
            fill="currentColor"
            p-id="2141"
        ></path>
        <path
            d="M277.333333 874.666667m-42.666666 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
            fill="currentColor"
            p-id="2142"
        ></path>
        <path
            d="M853.333333 874.666667m-42.666666 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
            fill="currentColor"
            p-id="2143"
        ></path>
    </svg>
));

const HeartIcon = forwardRef((props, ref) => (
    <svg
        t="1585124515213"
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4657"
        width="32"
        height="32"
        ref={ref}
    >
        <path
            d="M725.306 42.696c-83.56 0-159.122 34.342-213.306 89.668-54.202-55.326-129.746-89.668-213.32-89.668C133.732 42.696 0.018 176.41 0.018 341.342 0.018 682.69 512 981.304 512 981.304s511.982-298.616 511.982-639.962c0-164.932-133.714-298.646-298.676-298.646z"
            fill="currentColor"
            p-id="4658"
        ></path>
        <path
            d="M725.306 42.696a299.8 299.8 0 0 0-42.624 3.046c144.714 20.702 255.992 145.15 255.992 295.6 0 280.756-346.394 532.624-469.344 613.182 26.53 17.406 42.67 26.78 42.67 26.78s511.982-298.616 511.982-639.962c0-164.932-133.714-298.646-298.676-298.646z"
            fill="currentColor"
            opacity=".2"
            p-id="4659"
        ></path>
    </svg>
));

const MenuIcon = forwardRef((props, ref) => (
    <svg
        t="1585219948859"
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2332"
        width="32"
        height="32"
    >
        <path
            d="M128 469.333333m40.533333 0l686.933334 0q40.533333 0 40.533333 40.533334l0 4.266666q0 40.533333-40.533333 40.533334l-686.933334 0q-40.533333 0-40.533333-40.533334l0-4.266666q0-40.533333 40.533333-40.533334Z"
            fill="currentColor"
            p-id="2333"
        ></path>
        <path
            d="M128 682.666667m40.533333 0l686.933334 0q40.533333 0 40.533333 40.533333l0 4.266667q0 40.533333-40.533333 40.533333l-686.933334 0q-40.533333 0-40.533333-40.533333l0-4.266667q0-40.533333 40.533333-40.533333Z"
            fill="currentColor"
            p-id="2334"
        ></path>
        <path
            d="M128 256m40.533333 0l686.933334 0q40.533333 0 40.533333 40.533333l0 4.266667q0 40.533333-40.533333 40.533333l-686.933334 0q-40.533333 0-40.533333-40.533333l0-4.266667q0-40.533333 40.533333-40.533333Z"
            fill="currentColor"
            p-id="2335"
        ></path>
    </svg>
));

const LogoMenu = forwardRef((props, ref) => (
        <img src="/LogoMantaRayWhite.svg" width="250" height="100px" alt="Logo"/>
));


const Icons = {
    LeftArrowIcon,
    RightArrowIcon,
    CartIcon,
    HeartIcon,
    MenuIcon,
    LogoMenu
};

export default Icons;
export {LeftArrowIcon, RightArrowIcon, CartIcon, HeartIcon, MenuIcon, LogoMenu};
