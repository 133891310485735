import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import "../styles/layout-contact.css";

const useStyles = createUseStyles({
	text: {
		marginTop: "2rem",
		marginBottom: "2rem",
		color: "#333", // Escolha uma cor de texto adequada
		fontSize: "1.2rem",
		textAlign: "center",
	},
	title: {
		fontSize: "1.5rem",
		fontWeight: "bold",
		marginBottom: "1rem",
		color: "#444", // Cor do título
	},
	contactInfo: {
		marginTop: "1rem",
	},
	contactDetail: {
		display: "flex",
		alignItems: "center",
		marginBottom: "0.5rem",
	},
	icon: {
		marginRight: "0.5rem",
		fontSize: "1.2rem",
		color: "#666", // Cor do ícone
	},
});
const ContactPage = () => {
	const classes = useStyles();

	return (
		<Fragment>
			<div className="background">
				<div className="container">
					<div className="screen">
						<div className="screen-body">
							<div className="screen-body-item left">
								<div className="app-title">
									<span><strong> Visite-nos:< /strong> Convidamos você a visitar nossos projetos e testemunhar em primeira mão a harmonia entre arquitetura, tecnologia e
										exclusividade que nos define. Junte-se a nós em nossa jornada de criar lares que ultrapassam fronteiras, combinando o melhor do
										passado, presente e futuro.Entre em contato conosco e permita-nos ser parte da realização do seu sonho de um lar
										verdadeiramente excepcional.</span>
								</div>
							</div>
							<div className="screen-body-item">
								<div className="app-form">
									<div className="app-form-group">
										<label className={'label-color'} >Telefone:</label>
										<br/>
										<br/>
										<a className="app-form-control"  href={"https://api.whatsapp.com/send?phone=5541999917076"}> (41) 99991-7076 </a>
									</div>

									<div className="app-form-group ">
										<label className={'label-color'}  >Instagram:</label>
										<br/>
										<br/>
										<a className="app-form-control" href={"https://www.instagram.com/imoveismantaray/"} >@imoveismantaray</a>
									</div>

									<div className="app-form-group ">
										<label className={'label-color'}  >Cnpj:</label>
										<br/>
										<br/>
										<span className="app-form-control"  > 47.323.848/0001-15</span>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</Fragment>
	);
};

ContactPage.propTypes = {};

export default ContactPage;
