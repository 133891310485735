import React, { useState, useEffect, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Button } from "./shared";
import { LogoMenu } from "./shared/Icons";
import { SET_PAGE, SET_SLIDE } from "../reducers/";
import gsap from "gsap";

const useStyles = createUseStyles({
    row: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    brand: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        color: "white",
    },
    drawerBtn: {
        display: "none",
        marginRight: "0.85rem",
        "& svg": { width: "1.2rem" },
        "@media (max-width: 600px)": {
            display: "initial",
        },
    },
    tabs: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    tab: {
        fontFamily:"Optima, sans-serif",
        fontSize:"16px",
        background: "transparent",
        marginLeft: "1.85rem",
        marginRight: "1.85rem",
        color: "white",
        "& svg": {
            color: "white",
            width: "1.2rem",
            height: "1.2rem",
        },
    },
    mobileDrawer: {
        display: "none",
        flexDirection: "column",
        top: "4rem",
        left: 0,
        background: "transparent",
        zIndex: 1000,

        "@media (max-width: 600px)": {
            display: "flex",
        },
    },
    drawer: {
        "@media (max-width: 600px)": {
            position: "fixed",
            top: 51.2,
            left: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "flex-start",
            background: "white",
            zIndex: 900
        }
    },
    appbar: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        zIndex: 1000,
        color: "white",
        background: "rgba(128, 128, 128, 0.3)",
        "@media (max-width: 600px)": {
            padding: "0.5rem",
            background: "rgba(128, 128, 128, 0.3)",
            "& *": {
                color: "white",
            },
        },
    },
});

const AppBar = forwardRef(({ store, ...props }, ref) => {
    const classes = useStyles();

    const [cartAnchorEl, setCartAnchorEl] = useState(null);
    const [favAnchorEl, setFavAnchorEl] = useState(null);
    const [drawer, setDrawer] = useState(window.innerWidth > 600);
    const drawerRef = useRef();

    const slideRef = useRef(),
        titleRef = useRef(),
        numberRef = useRef(),
        numberVariableRef = useRef(),
        descriptionRef = useRef(),
        actionRef = useRef();

    const animateSlideOut = (
        slideRef,
        titleRef,
        numberRef,
        descriptionRef,
        actionRef,
        callback
    ) => {
        const tl = gsap.timeline();
        [titleRef, numberRef, descriptionRef, actionRef].forEach((ref) => {
            tl.to(
                ref.current,
                {
                    "clip-path": "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
                    duration: 0.32,
                },
                "out"
            );
        });
        tl.to(slideRef.current, { opacity: 0, duration: 0.32 }, "out+0.32").add(
            callback,
            1
        );
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setDrawer(window.innerWidth > 600);
        });
    }, []);

    const viewItems = (page) => {
        animateSlideOut(
            slideRef,
            titleRef,
            numberRef,
            descriptionRef,
            actionRef,
            () => {
                store.dispatch({ type: SET_SLIDE, slide: 0 });
                store.dispatch({ type: SET_PAGE, page });
            }
        );
    };

    return (
        <nav {...props} ref={ref} className={classes.appbar}>
            <div className={classes.row}>
                <a href="/" className={classes.brand} role="banner">
                    <LogoMenu />
                </a>
                <div className={classes.mobileDrawer} role="tabpanel" id="tabpanel">
                    <Button
                        key="Início"
                        className={classes.tab}
                        role="tab"
                        aria-controls="tabpanel"
                        onClick={() => {
                            viewItems(0);
                        }}
                    >
                        Início
                    </Button>
                    <Button
                        key="Contato"
                        className={classes.tab}
                        role="tab"
                        aria-controls="tabpanel"
                        onClick={() => {
                            viewItems(3);
                        }}
                    >
                        Contato
                    </Button>
                </div>
                <div className={classes.tabs} role="tabpanel" id="tabpanel" >
                    <div
                        className={classes.drawer}
                        ref={drawerRef}
                        style={{display: drawer ? "flex" : "none"}}
                    >

                        <Button
                            key="Início"
                            className={classes.tab}
                            role="tab"
                            aria-controls="tabpanel"
                            onClick={() => {
                                viewItems(0);
                            }}
                        >
                            Início
                        </Button>
                        <Button
                            key="Contato"
                            className={classes.tab}
                            role="tab"
                            aria-controls="tabpanel"
                            onClick={() => {
                                viewItems(3);
                            }}
                        >
                            Contato
                        </Button>

                    </div>
                </div>
            </div>
        </nav>
    )

});

AppBar.propTypes = {
    store: PropTypes.object.isRequired,
};

export default AppBar;
