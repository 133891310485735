import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	gridItem: {
		margin: 16,
		backgroundRepeat: "no-repeat",
		cursor: "pointer",
		backgroundImage: (props) => `url(${props.image})`,
		backgroundSize: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
	},
	span: {
		fontSize:"20px",
		color:"white",
		marginTop: 8,
	},
});

const GridItem = forwardRef(({ className, image, children, nome, ...props }, ref) => {
	const classes = useStyles({ image });
	return (
		<div
			{...props}
			role="gridcell img"
			ref={ref}
			className={classes.gridItem + (className ? " " + className : "")}
		>
			{children}
			<span className={classes.span}>{nome}</span>
		</div>

	);
});

GridItem.propTypes = {
	image: PropTypes.string.isRequired,
	nome:PropTypes.string.isRequired
};

export default GridItem;
