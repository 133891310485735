import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import App from "./components/App";
import reducers from "./reducers";

const store = createStore(reducers);
const root = createRoot(document.getElementById("root")); // Crie a raiz usando createRoot

const render = () => {
	root.render(
		<React.StrictMode>
			<App {...store.getState()} store={store} />
		</React.StrictMode>
	);
};

store.subscribe(render);
render();

// Se você deseja que seu aplicativo funcione offline e carregue mais rapidamente,
// pode substituir unregister() por register() abaixo.
// Saiba mais sobre service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
