import React, { useState, useEffect, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Button,  FavMenu, CartMenu } from "./shared";
import { SET_PAGE, SET_SLIDE } from "../reducers/";

const useStyles = createUseStyles({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    color: "white",
    zIndex: 1000
  },
  row: {
    display: "flex",
    alignItems: "center"
  },
  brand: {
    color:"white",
    fontSize: "0.8rem",
    fontWeight: "bold"
  },
  drawerBtn: {
    display: "none",
    marginRight: "0.85rem",
    "& svg": { width: "1.2rem" },

  },
  tabs: {
    display: "flex",
    alignItems: "center"
  },
  tab: {
    marginLeft: "0.85rem",
    marginRight: "0.85rem",
    "& svg": {
      width: "1.2rem",
      height: "1.2rem"
    }
  },
  drawer: {

  }
});

const AppFooter = forwardRef(({ cart, fav, store, ...props }, ref) => {
  const classes = useStyles();

  const [cartAnchorEl, setCartAnchorEl] = useState(null);
  const [favAnchorEl, setFavAnchorEl] = useState(null);
  const [drawer, setDrawer] = useState(window.innerWidth > 600);
  const drawerRef = useRef();

  const badgeContent = Object.keys(cart).reduce(
      (acc, key) => acc + cart[key].quantity,
      0
  );

  const onClickBrand = () => {
      window.open('https://infoteamsoftware.com/', '_blank');
  };

  const toggleCartMenu = e => {
    setCartAnchorEl(cartAnchorEl ? null : e.currentTarget);
  };

  const toggleFavMenu = e => {
    setFavAnchorEl(favAnchorEl ? null : e.currentTarget);
  };

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setDrawer(window.innerWidth > 600);
    });
  }, []);

  useEffect(() => {
    const onClick = () => {
      setCartAnchorEl(null);
      setFavAnchorEl(null);
    };
    if (cartAnchorEl || favAnchorEl)
      document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [cartAnchorEl, favAnchorEl]);

  return (
      <footer {...props} ref={ref} className={classes.footer}>
        <div className={classes.row}>
          <Button className={classes.brand} role="banner" onClick={onClickBrand}>
           Created By Info Team Software
          </Button>
        </div>
        <div className={classes.tabs}>
          <div className={classes.drawer}>
            {["Todos os direitos reservado"].map(tab => (
                <span
                    key={tab}
                    className={classes.tab}
                    role="tab"
                    aria-controls="tabpanel"
                >
                  {tab}
                </span>
            ))}
          </div>
        </div>
        <CartMenu anchorEl={cartAnchorEl} cart={cart} />
        <FavMenu anchorEl={favAnchorEl} fav={fav} />
      </footer>
  );
});

AppFooter.propTypes = {
  cart: PropTypes.object.isRequired,
  fav: PropTypes.object.isRequired
};

export default AppFooter;
